<script setup type="ts">
  import NavBar from "./NavBarView.vue";
  import VueDatePicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
</script>

<template>
  <NavBar />
  <div class="filtersouter">
    <VueDatePicker month-picker range v-model="dates" @update:model-value="onDatesUpdate" lang="en" />
  </div>
  <Bar
    v-if="loaded" 
    id="analytics-chart"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script type="ts">
  import moment from "moment"
  import colorLib from "@kurkle/color";
  import { getReport } from "../services/transactions";
  import { Bar } from "vue-chartjs"
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, LineController} from "chart.js"

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, LineController)

  export default {
    name: "BarChart",
    components: { Bar },
    data() {
      return {
        colors: ["#a83232", "#3832a8", "#3281a8", "#a8327d", "#5da832", "#5332a8", "#3271a8", "#8c32a8", "#a83263", "#9ea832", "#a83294", "#32a875", "#a87f32", "#3244a8", "#3ea832", "#a83232", "#79a832", "#7b32a8", "#32a898", "#a86732", "#3259a8", "#6932a8", "#a89832", "#a432a8", "#329ea8", "#a84832", "#a8324a"],
        loaded: false,
        dates: [],
        chartData: {
          labels: [],
          datasets: []
        },
        chartOptions: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              align: "start",
              onClick: this.onLegendClick,
              labels: {
                filter: item => item.datasetIndex !== 0
              }
            }
          }
        }
      }
    },
    methods: {
      transparentize(value, opacity) {
        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
        return colorLib(value).alpha(alpha).rgbString();
      },
      onLegendClick(e, legendItem, legend) {
        const index = legendItem.datasetIndex;
        const ci = legend.chart;
        if (ci.isDatasetVisible(index)) {
          ci.hide(0);
          ci.hide(index);
          legendItem.hidden = true;
          for (let i in ci.data.datasets[index].data) {
            ci.data.datasets[0].data[i] -= ci.data.datasets[index].data[i];
          }
          ci.show(0);
        } else {
          ci.hide(0);
          for (let i in ci.data.datasets[index].data) {
            ci.data.datasets[0].data[i] += ci.data.datasets[index].data[i];
          }
          ci.show(index);
          legendItem.hidden = false;
          ci.show(0);
        }  
      },
      onDatesUpdate(dates) {
        this.loaded = false;
        const fromMoment = moment(dates[0]).startOf("month").startOf("day");
        const toMoment = moment(dates[1]).endOf("month").endOf("day");

        getReport(fromMoment, toMoment).then((response) => {
          this.chartData.labels = response.dates;
          
          this.chartData.datasets = [];

          this.chartData.datasets.push({
            label: "Total",
            data: response.totalSpentAmounts,
            backgroundColor: "black",
            type: "line",
            order: 0,
            hidden: false
          });

          for (let i in response.categoriesStat) {
            let values = [];
            for (let dateStat of response.categoriesStat[i].datesStat) {
              values.push(dateStat);
            }
            this.chartData.datasets.push({
              label: response.categoriesStat[i].category.name,
              data: values,
              borderColor: this.colors[i % this.colors.length],
              backgroundColor: this.transparentize(this.colors[i % this.colors.length], 0.5),
              borderSkipped: false,
              order: i + 1,
              hidden: false
            });
          }

          this.loaded = true;
        })
      }
    },
    mounted() {
      const fromMoment = moment(new Date()).subtract(11, "months").startOf("month").startOf("day");
      const toMoment = moment(new Date()).endOf("month").endOf("day");

      this.dates = [
        {
          year: fromMoment.year(),
          month: fromMoment.month()
        },
        {
          year: toMoment.year(),
          month: toMoment.month()
        }
      ];
      this.onDatesUpdate(this.dates);
    }
  }
</script>

<style>
.filtersouter {
  width: 250px;
}
</style>
